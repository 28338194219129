import { createStore } from '@stencil/store';
import { User } from '../types/users';
import { App } from '../types/apps';

export const { state, onChange } = createStore({
  projects: [],
  apps: [] as App[],
  users: [] as User[],
  isAuthenticated: false,
});

onChange('projects', (projects) => {
  localStorage.setItem('projects', JSON.stringify(projects));
});

onChange('apps', (apps) => {
  localStorage.setItem('apps', JSON.stringify(apps));
});

onChange('users', (users) => {
  localStorage.setItem('users', JSON.stringify(users));
});